/* fonts.css */

@font-face {
    font-family: 'HelveticaNeue-Black';
    src: url('./helvetica/HelveticaNeueBlack.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-BlackItalic';
    src: url('./helvetica/HelveticaNeueBlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-Bold';
    src: url('./helvetica/HelveticaNeueBold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-BoldItalic';
    src: url('./helvetica/HelveticaNeueBoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-Heavy';
    src: url('./helvetica/HelveticaNeueHeavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-HeavyItalic';
    src: url('./helvetica/HelveticaNeueHeavyItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
  }

  
  @font-face {
    font-family: 'HelveticaNeue-Italic';
    src: url('./helvetica/HelveticaNeueItalic.ttf') format('opentype');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-Light';
    src: url('./helvetica/HelveticaNeueLight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-LightItalic';
    src: url('./helvetica/HelveticaNeueLightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-Medium';
    src: url('./helvetica/HelveticaNeueMedium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-MediumItalic';
    src: url('./helvetica/HelveticaNeueMediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-Roman';
    src: url('./helvetica/HelveticaNeueRoman.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-Thin';
    src: url('./helvetica/HelveticaNeueThin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-ThinItalic';
    src: url('./helvetica/HelveticaNeueThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-UltraLight';
    src: url('./helvetica/HelveticaNeueUltraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeue-UltraLightItalic';
    src: url('./helvetica/HelveticaNeueUltraLightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
  }



/* Montserrat font declarations */
@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/static/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Montserrat-Variable';
    src: url('./Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype-variations');
    font-weight: 100 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat-Variable';
    src: url('./Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('truetype-variations');
    font-weight: 100 900;
    font-style: italic;
  }